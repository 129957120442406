import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";

const NotificationRepository = RepositoryFactory.get("notification");

export const notifications = {
  namespaced: true,
  state: {
    notifications: [],
    status: {
      loading: false,
    },
  },

  actions: {
    async getNotifications({ dispatch, commit, rootState }) {
      // Cancel notification retrieval if the user is not logged in
      if (!rootState.users.status.loggedIn) return;

      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await NotificationRepository.get(user, user.userId)
        .then((notifications) => {
          commit("GET_NOTIFICATIONS", notifications.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
    },

    async deactivateNotification(
      { dispatch, commit, rootState },
      notificationId
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await NotificationRepository.deactivate(user, notificationId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getNotifications", { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async deactivateAllNotifications({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await NotificationRepository.deactivateAll(user, user.userId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getNotifications", { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },
  },
};
