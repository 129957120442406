import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
const AppRepository = RepositoryFactory.get("applications");

export const applications = {
  namespaced: true,
  state: {
    currentApplication: {},
    tags: [],
    gateways: [],
    tagData: [],
    status: {
      loading: false,
      loggedIn: false,
    },
  },

  actions: {
    async createApplication(
      { dispatch, commit, rootState },
      { applicationPayload }
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var companyId = rootState.users.userCompany.companyId;

      await AppRepository.create(user, companyId, applicationPayload)
        .then((application) => {
          dispatch("companies/getCompanyApplications", null, { root: true });
          commit("CREATE_APPLICATION", application);
          commit("LOADING", false);
          dispatch("alert/success", i18n.t("common.created"), { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async update({ dispatch, commit, rootState }, { id, applicationPayload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AppRepository.update(user, id, applicationPayload)
        .then(() => {
          dispatch("companies/getCompanyApplications", null, { root: true });
          dispatch("alert/success", i18n.t("common.updated"), { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async deleteApplication(
      { dispatch, commit, rootState },
      { applicationId }
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AppRepository.delete(user, applicationId)
        .then(() => {
          commit("DELETE_APPLICATION");
          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          dispatch("companies/getCompanyApplications", null, { root: true });
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getApplication({ dispatch, commit, rootState }, id) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await AppRepository.get(user, id)
        .then((res) => {
          commit("SET_APPLICATION", res.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response !== undefined && res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
    },

    async setApplication({ commit }, application) {
      commit("SET_APPLICATION", application);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    CREATE_APPLICATION(state, application) {
      state.currentApplication = application;
    },

    DELETE_APPLICATION(state) {
      state.currentApplication = {};
    },

    GET_TAG_DATA(state, tagData) {
      state.tagData = tagData;
    },

    SET_APPLICATION(state, application) {
      state.currentApplication = application;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        currentApplication: {},
        tags: [],
        gateways: [],
        tagData: [],
        status: {
          loading: false,
          loggedIn: false,
        },
      };
    },
  },
};
