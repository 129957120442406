import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
import MetricHelper from "@/_helpers/metricHelper.js";

const LogRepository = RepositoryFactory.get("log");

export const logs = {
  namespaced: true,
  state: {
    logs: [],
    staticLogs: [],
    activeUsers: [],
    activeAlarms: [],
    lastRequestLogs: [],
    datapoints: null,
    status: {
      loading: false,
    },
  },

  actions: {
    async clearLog({ commit }) {
      commit("CLEAR_LOG");
    },

    async get({ dispatch, commit, rootState }, metrics) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await LogRepository.get(user, MetricHelper.getMetricsUrlParams(metrics))
        .then((tags) => {
          commit("GET_LOGS", tags.data);
          commit("GET_STATIC_LOGS", tags.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      commit("LOADING", false);
    },

    async activeUsers({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await LogRepository.getActiveUsers(user)
        .then((users) => {
          commit("GET_ACTIVEUSERS", users.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      commit("LOADING", false);
    },

    async getDatapoints({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await LogRepository.getDatapoints(user)
        .then((points) => {
          commit("GET_DATAPOINTS", points.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getActiveAlarms({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await LogRepository.getActiveAlarms(user)
        .then((alarms) => {
          commit("GET_ALARMS", alarms.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      commit("LOADING", false);
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_DATAPOINTS(state, points) {
      state.datapoints = points;
    },

    GET_ALARMS(state, alarms) {
      state.activeAlarms = alarms;
    },

    GET_STATIC_LOGS(state, logs) {
      state.staticLogs = logs;
    },

    GET_LOGS(state, logs) {
      if (state.logs.length > 0) {
        logs.forEach((e) => state.logs.unshift(e));
        state.lastRequestLogs = logs.reverse();

        if (state.logs.length > 200) {
          var len = state.logs.length - 200;
          state.logs.splice(200, len);
        }
      } else {
        state.logs = logs;
      }
    },

    GET_ACTIVEUSERS(state, users) {
      state.activeUsers = users;
    },

    CLEAR_LOG(state) {
      state.logs = [];
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        activeUsers: [],
        logs: [],
        status: {
          loading: false,
        },
      };
    },
  },
};
