<template>
  <v-menu bottom offset-y v-model="notificationTray">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab small v-bind="attrs" v-on="on">
        <v-badge
          :content="notifications.length"
          :value="notifications.length"
          color="error"
          overlap
        >
          <v-icon medium>{{
            notifications.length > 0 ? "mdi-bell-ring" : "mdi-bell"
          }}</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list
      v-for="notification in notifications"
      v-bind:key="notification.notificationId"
      max-width="600px"
      min-height="80px"
      max-height="600px"
      style="overflow: scroll; padding: 1rem"
      class="hide-scrollbar"
    >
      <v-list-item
        @click="
          clearNotifications(notification.notificationId, notification.route)
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ notification.label }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ notification.message }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-list
      v-if="notifications.length <= 0"
      max-width="600px"
      min-width="450px"
    >
      <v-list-item-content>
        <v-list-item-title class="text-center">{{
          $t("notifications.noNotifications")
        }}</v-list-item-title>
        <v-list-item-subtitle></v-list-item-subtitle>
      </v-list-item-content>
    </v-list>
    <v-btn color="primary" block @click="clearNotifications(null)">{{
      $t("notifications.clearNotifications")
    }}</v-btn>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";
export { Roles } from "@/_helpers/Role.js";

export default {
  name: "Notification",
  data() {
    return {
      notificationTray: false,
      interval: undefined,
    };
  },

  computed: {
    ...mapState("notifications", ["notifications"]),
    ...mapState("users", ["currentUser"]),
  },

  methods: {
    ...mapActions("notifications", [
      "deactivateAllNotifications",
      "deactivateNotification",
      "getNotifications",
    ]),

    async clearNotifications(id, route = "") {
      if (id == null || id == undefined) {
        this.deactivateAllNotifications();
        return;
      }

      await this.deactivateNotification(id);
      if (route != "") this.$router.push(route);
    },
  },

  async created() {
    await this.getNotifications();
    this.interval = setInterval(() => this.getNotifications(), 10 * 60 * 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  watch: {
    notificationTray(val) {
      if (val) {
        this.getNotifications();
      }
    },
  },
};
</script>
<style>
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>