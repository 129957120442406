import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/notification";

export default {
    get(user, userId) {
        var config = {
            headers: getAuthHeader(user)
        };

        return Repository.get(`${resource}/${userId}`, config);
    },

    deactivate(user, notificationId) {
        var config = {
            headers: getAuthHeader(user)
        };

        return Repository.put(`${resource}/deactivate/${notificationId}`, {}, config);
    },

    deactivateAll(user, userId) {
        var config = {
            headers: getAuthHeader(user)
        };

        return Repository.put(`${resource}/deactivate/all/${userId}`, {}, config);
    }
};
