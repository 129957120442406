import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
import CsharpEnum from "@/_helpers/CsharpEnum";
const ModuleRepository = RepositoryFactory.get("module");

export const modules = {
  namespaced: true,
  state: {
    currentModule: null,
    modules: [],
    status: {
      loading: false,
    },
  },

  actions: {
    async getModules({ dispatch, commit, rootState }, { dashboardId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ModuleRepository.get(user, dashboardId)
        .then((modules) => {
          commit("SET_MODULES", modules.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getModule({ dispatch, commit, rootState }, { moduleId }) {
      commit("LOADING", true);
      var user = rootState.users.currentUser;

      if (moduleId === undefined || moduleId === null)
        moduleId = rootState.modules.currentModule.dashboardModuleId;

      await ModuleRepository.getModule(user, moduleId)
        .then((mod) => {
          commit("ADD_MODULE", mod.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async setModules({ commit }, modules) {
      commit("SET_MODULES", modules);
    },

    async create({ dispatch, commit, rootState }, { dashboardId, payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ModuleRepository.create(user, dashboardId, payload)
        .then((mod) => {
          if (
            (mod.data.type !== CsharpEnum.ModuleType.IMAGEMAP &&
              mod.data.type !== CsharpEnum.ModuleType.IMAGE) ||
            (CsharpEnum.ModuleType.IMAGE === mod.data.type &&
              mod.data.tag !== null)
          ) {
            commit("ADD_MODULE", mod.data);
          } else {
            commit("SET_CURRENT", mod.data);
          }

          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async update({ dispatch, commit, rootState }, { moduleId, payload }) {
      commit("LOADING", true);
      commit("SET_CURRENT", payload);

      var user = rootState.users.currentUser;
      var dashboardId = rootState.dashboards.currentDashboard.dashboardId;
      await ModuleRepository.update(user, moduleId, payload)
        .then(() => {
          dispatch("getModules", {
            dashboardId: dashboardId,
          });

          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async copyModule({ dispatch, rootState }, moduleId) {
      var user = rootState.users.currentUser;
      await ModuleRepository.copyModule(user, moduleId)
        .then(() => {
          dispatch("getModules", {
            dashboardId: rootState.dashboards.currentDashboard.dashboardId,
          });
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async updateModules({ commit, rootState }, payload) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      await ModuleRepository.updateModules(user, payload)
        .then(() => {
          commit("LOADING", false);
        })
        .catch(() => {
          commit("LOADING", false);
        });
    },

    async delete({ dispatch, commit, rootState }, { moduleId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ModuleRepository.delete(user, moduleId)
        .then(() => {
          dispatch("getModules", {
            dashboardId: rootState.dashboards.currentDashboard.dashboardId,
          });
          commit("LOADING", false);
        })

        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async postImage({ dispatch, commit, rootState }, { moduleId, image }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ModuleRepository.postImage(user, moduleId, image)
        .then(() => {
          dispatch("getModule", {
            moduleId: rootState.modules.currentModule.dashboardModuleId,
          });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async updateIndex({ commit, rootState }) {
      commit("LOADING", true);
      var user = rootState.users.currentUser;
      var modules = rootState.modules.modules;

      for (let i = 0; i < modules.length; i++) {
        if (modules[i].dashboardModuleId !== undefined) {
          modules[i].index = i;
          await ModuleRepository.update(
            user,
            modules[i].dashboardModuleId,
            modules[i]
          );
        }
      }

      commit("LOADING", false);
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_CURRENT(state, mod) {
      state.currentModule = mod;
    },

    ADD_MODULE(state, mod) {
      state.currentModule = mod;

      state.modules.push(mod);
    },

    SET_MODULES(state, modules) {
      state.modules = modules;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        modules: [],
        status: {
          loading: false,
        },
      };
    },
  },
};
