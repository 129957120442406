import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import Languages from "@/languages";
import i18n from "../../plugins/i18n";

Vue.use(VueLocalStorage);
const supportedLanguages = Object.getOwnPropertyNames(Languages);

export const languages = {
  namespaced: true,
  state: {
    language: Vue.localStorage.get("language")
  },

  mutations: {
    SET_LANGUAGE(state, lang) {
      Vue.localStorage.set("language", lang);
      state.language = lang;
      i18n.locale = lang;
    }
  },

  actions: {
    setLanguage({ commit }, languages) {
      if (typeof languages === "string") {
        commit("SET_LANGUAGE", languages);
      } else if (languages != null) {
        const language = supportedLanguages.find(sl =>
          languages.find(l =>
            l.split(new RegExp(sl, "gi")).length - 1 > 0 ? sl : null
          )
        );

        commit("SET_LANGUAGE", language);
      }
    }
  }
};
