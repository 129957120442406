import i18n from "@/plugins/i18n";

export default {
  decoders: [
    { name: i18n.t("tag.chooseDecoder"), key: "" },
    { name: "Acrios LoadCell", key: "acrios_load" },
    { name: "Adeunis Temp", key: "adeunis_temp" },
    { name: "Adeunis 4DI", key: "adeunis_4di" },
    { name: "Adeunis 50A", key: "adeunis50a" },
    { name: "Adeunis 100A", key: "adeunis100a" },
    { name: "Aqualabo", key: "aqualabo" },
    { name: "Ellenex", key: "ellenex" },
    { name: "Elsys", key: "elsys" },
    { name: "Hummbox", key: "hummbox" },
    { name: "Tecscale", key: "tecscale" },
    { name: "Yabby Gps", key: "yabby_gps" },
    { name: "Guppy Gps", key: "guppy_gps" },
    { name: "Oyster Gps", key: "oyster_gps" },
    { name: "Ursalink N1", key: "ursalink_n1" },
    { name: "Ursalink T1", key: "ursalink_t1" },
    { name: "Radiobrige DI", key: "radiobridge_di" },
    { name: "SigFox Tempguard", key: "sigfox_tempguard" },
    { name: "Sensative Strips", key: "strips" },
    { name: "Axiom Water Gauge", key: "axiom_water_guage" },
    { name: "Epic Sensor", key: "epicwlt" },
    { name: "Ecoadapt", key: "ecoadapt" },
    { name: "Squid Decoder", key: "squid" },
    { name: "IMBuildings People Counter", key: "people_counter" },
    { name: "EnLink", key: "enlink" },
    { name: "Ursalink AM100", key: "ursalink_am100" },
    { name: "Ursalink EM500", key: "ursalink_em500" },
    { name: "Digital Matter Sensordata", key: "dm_sensordata" },
    { name: "MCF88", key: "mcf88" },
    { name: "ELA Innovation", key: "ela_innovation" },
    { name: "Polysense", key: "polysense" },
    { name: "Robeau DN20", key: "robeau_dn20" },
    { name: "Robeau DN25", key: "robeau_dn25" },
    { name: "Pycom Scale", key: "pycomscale" },
    {
      name: "MCF88 RS485 Thermoelement",
      key: "mcf88_rs485_thermoelement",
    },
    { name: "Raw Data", key: "raw" },
    { name: "Netvox 150", key: "netvox_150" },
    { name: "Tekbox TBS12", key: "tekbox_tbs12" },
    { name: "Tektelic Agri", key: "tektelic_agri" },
    { name: "Talkpool OY1210", key: "talkpool_oy1210" },
    { name: "IoT-AB Pycom", key: "iot_pycom" },
    { name: "Keller ADT1", key: "keller_adt1" },
    { name: "Snow Depth Lidar", key: "snow_depth" },
    { name: "Laird RS1XX", key: "laird_rs1xx" },
    { name: "Browan TBMS100", key: "browan_tbms100" },
    { name: "Infinite Informatics ADS270", key: "infinite_ads270" },
    { name: "ParametricPCR2", key: "parametric_pcr2" },
    { name: "Adeunis Field Tester", key: "adeunis_ftd" },
    { name: "ICT International EF NODE", key: "ict_ef_node" },
    { name: "Decentlab Depth Temp", key: "dl_depth" },
    { name: "Decentlab Range Finder", key: "decentlab_range_finder" },
    { name: "Decentlab Pressure Temp", key: "decentlab_pressure_temp" },
    { name: "Decentlab 10HS", key: "decentlab_10hs" },
    { name: "Decentlab 5TE", key: "decentlab_5te" },
    { name: "Decentlab 5TM", key: "decentlab_5tm" },
    { name: "Decentlab AC", key: "decentlab_ac" },
    { name: "Decentlab ATM22", key: "decentlab_atm22" },
    { name: "Decentlab ATM41", key: "decentlab_atm41" },
    { name: "Decentlab CTD10", key: "decentlab_ctd10" },
    { name: "Decentlab DLR2002", key: "decentlab_dlr2002" },
    { name: "Decentlab DLR2003", key: "decentlab_dlr2003" },
    { name: "Decentlab DLR2004", key: "decentlab_dlr2004" },
    { name: "Decentlab DLR2005", key: "decentlab_dlr2005" },
    { name: "Decentlab DLR2006", key: "decentlab_dlr2006" },
    { name: "Decentlab DLR2008", key: "decentlab_dlr2008" },
    { name: "Decentlab DLR20010", key: "decentlab_dlr20010" },
    { name: "Decentlab DLR20012", key: "decentlab_dlr20012" },
    { name: "Decentlab DS18", key: "decentlab_ds18" },
    { name: "Decentlab GS3", key: "decentlab_gs3" },
    { name: "Decentlab IAM", key: "decentlab_iam" },
    { name: "Decentlab ITST", key: "decentlab_itst" },
    { name: "Decentlab KL66", key: "decentlab_kl66" },
    { name: "Decentlab LP8P", key: "decentlab_lp8p" },
    { name: "Decentlab MBX", key: "decentlab_mbx" },
    { name: "Decentlab OPTOD", key: "decentlab_optod" },
    { name: "Decentlab PAR", key: "decentlab_par" },
    { name: "Decentlab PR21", key: "decentlab_pr21" },
    { name: "Decentlab PR26", key: "decentlab_pr26" },
    { name: "Decentlab PR36", key: "decentlab_pr36" },
    { name: "Decentlab PR36CTD", key: "decentlab_pr36ctd" },
    { name: "Decentlab PYR", key: "decentlab_pyr" },
    { name: "Decentlab RHC", key: "decentlab_rhc" },
    { name: "Decentlab SHT21", key: "decentlab_sht21" },
    { name: "Decentlab SHT35", key: "decentlab_sht35" },
    { name: "Decentlab SMTP", key: "decentlab_smtp" },
    { name: "Decentlab TBRG", key: "decentlab_tbrg" },
    { name: "Decentlab TRS11", key: "decentlab_trs11" },
    { name: "Decentlab TRS12", key: "decentlab_trs12" },
    { name: "Decentlab TRS21", key: "decentlab_trs21" },
    { name: "Decentlab ZN1", key: "decentlab_zn1" },
    { name: "Decentlab ZN2", key: "decentlab_zn2" },
  ],
};
