<template>
  <v-row>
    <v-col sm="12" md="7">
      <vc-date-picker
        mode="range"
        v-model="ranges"
        is-inline
        is-range
        style="max-width: 100%"
      />
    </v-col>

    <v-col sm="12" md="5">
      <v-row>
        <v-col sm="12" md="12" v-for="r in setRanges" v-bind:key="r.index">
          <v-chip
            style="width: 100%"
            class="text-center"
            color="primary"
            @click="changeRange(r.range)"
            >{{ r.displayName }}</v-chip
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DatePicker",

  props: {
    module: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      isPresetValue: false,
      ranges: {
        start: new Date(new Date() - this.timeFromHours(24)),
        end: new Date(),
      },
      setRanges: [
        {
          index: 0,
          displayName: this.$t("common.dateRange.0"),
          range: {
            end: new Date(),
            start: new Date(new Date() - this.timeFromHours(1)),
          },
        },

        {
          index: 1,
          displayName: this.$t("common.dateRange.1"),
          range: {
            end: new Date(),
            start: new Date(new Date() - this.timeFromHours(5)),
          },
        },

        {
          index: 2,
          displayName: this.$t("common.dateRange.2"),
          range: {
            end: new Date(),
            start: new Date(new Date() - this.timeFromHours(24)),
          },
        },

        {
          index: 3,
          displayName: this.$t("common.dateRange.3"),
          range: {
            end: new Date(),
            start: new Date(new Date() - this.timeFromDays(7)),
          },
        },

        {
          index: 4,
          displayName: this.$t("common.dateRange.4"),
          range: {
            end: new Date(),
            start: new Date(new Date() - this.timeFromDays(30)),
          },
        },
      ],
    };
  },

  methods: {
    changeRange(newRanges) {
      this.ranges = newRanges;
      this.isPresetValue = true;
    },
  },

  watch: {
    ranges(value) {
      if (!this.isPresetValue) {
        value.start.setHours(0);
        value.end.setHours(23);
        value.end.setMinutes(59);
      }

      this.$emit("update-range", value);
      this.isPresetValue = false;
      if (this.module !== undefined) {
        this.storeTimeOffset(
          this.module.dashboardModuleId,
          new Date() - value.start
        );
      }
    },
  },
};
</script>