import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/script";

export default {
  getScripts(user, companyId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  getScript(user, scriptId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/${scriptId}`, config);
  },

  create(user, companyId, payload) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.post(`${resource}/${companyId}`, payload, config);
  },

  put(user, scriptId, payload) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.put(`${resource}/${scriptId}`, payload, config);
  },

  delete(user, scriptId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.delete(`${resource}/${scriptId}`, config);
  },

  test(user, payload, companyId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.post(`${resource}/test/${companyId}`, payload, config);
  },
  execute(user, scriptId) {
    var config = {
      headers: getAuthHeader(user)
    };

    return Repository.get(`${resource}/run/${scriptId}`, config);
  }
};
